import { utf8ToSjis } from './encoding'

export function downloadBlob(blob, filename) {
    let a = document.createElement('a');
    a.href = URL.createObjectURL(blob);
    a.download = filename;

    document.body.appendChild(a);
    a.click();

    URL.revokeObjectURL(a.href);
    document.body.removeChild(a);
}

export function downloadCsv(csv, filename) {
    csv = utf8ToSjis(csv);

    let blob = new Blob([csv], {type:'text/csv'});
    downloadBlob(blob, filename);
}

export function downloadExcel(excel, filename) {
    let blob = new Blob([excel], {type:excel.type});
    downloadBlob(blob, filename);
}
